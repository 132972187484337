import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./assets/libs/@mdi/font/css/materialdesignicons.min.css";
import Index from "./pages/index/indexAlexHosting";
import Navbar from "./component/Navbar/navbar";
import PageTerms from "./pages/utility/pageTerms";
import PageCookies from "./pages/utility/pageCookies";
import PagePrivacy from "./pages/utility/pagePrivacy";
import PageMaintenance from "./pages/special/pageMaintenance";
import Switch from "./component/Switch";
import PageError from "./pages/special/pageError";
import PageComingsoon from "./pages/special/pageComingsoon";

export default function App() {
  return (
    <BrowserRouter>
      <Switch />
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route exact path="/index" element={<Index />} />
        <Route exact path="/navbar" element={<Navbar />} />

        <Route exact path="/terms" element={<PageTerms />} />
        <Route exact path="/cookies" element={<PageCookies />} />

        <Route exact path="/privacy" element={<PagePrivacy />} />

        <Route exact path="/page-error" element={<PageError />} />
        <Route exact path="/page-comingsoon" element={<PageComingsoon />} />
      </Routes>
    </BrowserRouter>
  );
}
