import {
  LiaCrosshairsSolid,
  BiLineChart,
  FiAirplay,
  FiFileText,
  GoRocket,
  GoClock,
  LuUsers,
  LuSearch,
  BiUserVoice,
  BiCreditCardAlt,
  FaRegQuestionCircle,
  MdOutlineCollectionsBookmark,
  HiOutlineCog6Tooth,
  RiPresentationFill,
  LiaFileInvoiceDollarSolid,
  RiMoneyCnyBoxLine,
  MdOutlineJoinInner,
  MdOutlineSelectAll,
  AiOutlineFire,
  LiaRulerCombinedSolid,
  TbBuildingSkyscraper,
  TbBuildingMonument,
  LiaMoneyCheckAltSolid,
  LiaUniversitySolid,
  LuPizza,
  FaRegBuilding,
  BiWater,
  LiaDropbox,
  MdCenterFocusWeak,
  AiOutlineDollar,
  BsShieldCheck,
  FiThumbsUp,
  TbKeyboardShow,
  TbAward,
  BsBookmark,
  FaRegStar,
  LuClock,
  FiAlertTriangle,
  FiPercent,
  FiBriefcase,
  FiLayers,
  FiLock,
  FiTool,
  FiBarChart2,
} from "../assets/icons/icons";

import * as Icon from "react-feather";

export const startupAbout = [
  {
    icon: BiWater,
    title: "24/7 Support",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: LiaDropbox,
    title: "Take Ownership",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    icon: MdCenterFocusWeak,
    title: "Take Ownership",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];
//https://react-icons.github.io/react-icons/icons/fi/
export const startupServices = [
  {
    icon: FiBriefcase,
    title: "Cloud Migration Services",
    desc: "Smooth transition of your existing infrastructure to AWS cloud, ensuring minimal disruption and maximum efficiency",
  },
  {
    icon: FiLayers,
    title: "Architecture Design and Optimization",
    desc: "Customized architecture design tailored to your specific business requirements, with ongoing optimization to enhance performance and cost-effectiveness",
  },
  {
    icon: FiLock,
    title: "Security and Compliance Solutions",
    desc: "Implementing robust security measures and ensuring compliance with industry standards to safeguard your data and meet regulatory requirements",
  },
  {
    icon: FiPercent,
    title: "Cost Management and Optimisation",
    desc: "Continuous monitoring and optimisation of your AWS resources to minimise costs while maximising value and performance",
  },
  {
    icon: FiTool,
    title: "Cloud Automation and DevOps",
    desc: "Leveraging automation tools and best practices to streamline deployment, management, and scaling of your applications on AWS cloud",
  },
  {
    icon: FiAlertTriangle,
    title: "Disaster Recovery and Business Continuity",
    desc: "Designing and implementing comprehensive disaster recovery plans to ensure business continuity in case of unforeseen events or disruptions",
  },
  {
    icon: RiPresentationFill,
    title: "Training and Support",
    desc: "Providing training programs and ongoing support to empower your team with the knowledge and skills needed to effectively utilize AWS cloud services",
  },
  {
    icon: FiBarChart2,
    title: "Performance Monitoring and Analytics",
    desc: "Monitoring and analyzing the performance of your AWS infrastructure to identify bottlenecks, optimize resource utilization, and ensure optimal performance at all times",
  },
];
