import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

import imageP from "../../assets/images/hosting/2.png";

import Navbar from "../../component/Navbar/navbar";
import Footer from "../../component/Footer/footer";

import CookieModal from "../../component/cookieModal";

import CompanyLogo from "../../component/companyLogo";
import * as Icon from "react-feather";

import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

import { startupServices } from "../../data/SpoeckerCloudServices";
import { emailConfig } from "../../config";

export default function IndexHosting() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [messageSentSuccessful, setMessageSentSuccessful] = useState(false);
  const [messageSendingError, setMessageSendingError] = useState("");
  const refCaptcha = useRef();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(name);
    console.log(email);
    console.log(message);
    const token = refCaptcha.current.getValue();
    console.log(token);

    if (name !== "") {
      if (email !== "") {
        if (validateEmail(email)) {
          if (message !== "") {
            if (token !== "") {
              emailjs
                .send(
                  emailConfig.service,
                  emailConfig.template,
                  {
                    fromName: name,
                    email: email,
                    message: message,
                    "g-recaptcha-response": token,
                  },
                  {
                    publicKey: emailConfig.publicKey,
                  }
                )
                .then(
                  () => {
                    console.log("SUCCESS!");
                    setMessageSentSuccessful(true);
                    setMessageSendingError("");
                  },
                  (error) => {
                    console.log("FAILED...", error.text);
                    setMessageSentSuccessful(false);
                    setMessageSendingError("Failed to send the message");
                  }
                );
              setName("");
              setEmail("");
              setMessage("");
            } else {
              setMessageSendingError("Please verify you are not a robot");
            }
          } else {
            setMessageSendingError("Message is required");
          }
        } else {
          setMessageSendingError("Email is not valid");
        }
      } else {
        setMessageSendingError("Email is required");
      }
    } else {
      setMessageSendingError("Name is required");
    }
  };

  return (
    <>
      <Navbar navClass="nav-light" />

      <section className="relative table w-full py-36 lg:py-52 bg-[url('../../assets/images/hosting/bg-hosting.jpg')] bg-center bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/80 to-black"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 text-white">
              Navigate the Cloud with Confidence
            </h3>

            <p className="text-slate-300 text-lg max-w-xl mx-auto">
              Your AWS Experts at Every Step!
            </p>

            <div className="mt-8">
              <Link
                reloadDocument
                to="#Contact"
                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container relative">
          <div className="grid grid-cols-1">
            <div className="relative -top-[52px] shadow dark:shadow-gray-800 py-6 bg-white dark:bg-slate-900">
              <CompanyLogo />
            </div>
          </div>
        </div>
      </section>
      <section>
        {/*<div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Our Services
            </h3>

            <p
              className="text-slate-400 max-w-xl mx-auto"
              style={{ maxWidth: "30px" }}
            >
              Harnessing AWS's Cutting-Edge Technologies, We Tailor Solutions to
              Fit Your Unique Needs, Ensuring Seamless Integration and Maximum
              Efficiency. With Us by Your Side, Sky's Not the Limit - It's Just
              the Beginning!
            </p>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
            {startupServices.map((item, index) => {
              let Icons = item.icon;
              return (
                <div
                  key={index}
                  className="group p-6 rounded-lg shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 hover:-translate-y-2 transition-all duration-500 ease-in-out"
                >
                  <div className="w-16 h-16 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                    <Icons className="w-6 h-6" />
                  </div>

                  <div className="content mt-7">
                    <Link
                      to="/page-services"
                      className="title h5 text-lg font-medium hover:text-indigo-600"
                    >
                      {item.title}
                    </Link>
                    <p className="text-slate-400 mt-3">{item.desc}</p>

                    <div className="mt-5">
                      <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">
                        Read More <FaArrowRight className="ms-2 text-[10px]" />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        */}
        <div id="Services" className="container relative">
          <div className="grid grid-cols-1 pb-8 items-end">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Our Services
            </h3>
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {startupServices.map((Data, index) => {
              const Icons = Data.icon;
              return (
                <div
                  key={index}
                  className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden"
                >
                  <div className="relative overflow-hidden text-transparent -m-3">
                    <Icon.Hexagon className="h-24 w-24 fill-indigo-600/5 group-hover:fill-white/10"></Icon.Hexagon>
                    <div className="absolute top-2/4 -translate-y-2/4 start-8 text-indigo-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                      <Icons className="w-7 h-7" />
                    </div>
                  </div>

                  <div className="mt-6">
                    <Link className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out">
                      {Data.title}
                    </Link>
                    <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">
                      {Data.desc}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="pt-20 pb-32 w-full table relative bg-[url('../../assets/images/business/bg02.jpg')] bg-center bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-slate-900/70"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 text-center">
            <h3 className="mb-4 md:text-3xl text-2xl text-white font-medium">
              Speak With Our Expert <br /> Cloud Solution Architects!
            </h3>

            <p className="text-white/80 max-w-xl mx-auto mb-0">
              Start working with Spoecker Cloud Consulting that can provide
              everything you need for your successful Cloud Journey.
            </p>

            <div className="mt-6">
              <Link
                reloadDocument
                to="#Contact"
                className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"
              >
                Get in touch
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:py-24 py-16 pt-5">
        <div className="container relative md:mt-24 mt-16">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="lg:col-span-6 md:order-2 order-1">
              <div className="lg:ms-8">
                <img src={imageP} alt="" />
              </div>
            </div>
            <div className="lg:col-span-6 md:order-1 order-2">
              <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                Powerful Digitalization <br /> With Spoecker Cloud Consulting
              </h3>
              <p className="text-slate-400 max-w-xl">
                Beyond our proven track record and industry-leading expertise,
                we're committed to forging lasting partnerships with our
                clients. By truly understanding your unique challenges and
                goals, we tailor our solutions to fit seamlessly into your
                business strategy. From startups to enterprises, we're here to
                guide you through every stage of your cloud journey, ensuring
                not just success, but sustained growth and innovation. With our
                dedicated team by your side, the possibilities in the cloud are
                endless.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="Contact"
        className="relative md:pt-11 pt-3 md:pb-24 pb-16 overflow-hidden"
      >
        <div className="container relative">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="lg:col-span-5 md:order-2 order-1">
              <div className="lg:ms-10 relative">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-medium">
                    Get in touch
                  </h3>

                  <form>
                    <div className="grid grid-cols-1 gap-4">
                      <div className="text-start">
                        <label htmlFor="name" className="font-semibold">
                          Your Name:
                        </label>
                        <div className="form-icon relative mt-2">
                          <Icon.User className="w-4 h-4 absolute top-3 start-4"></Icon.User>
                          <input
                            name="name"
                            id="name"
                            type="text"
                            className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Name :"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="text-start">
                        <label htmlFor="email" className="font-semibold">
                          Your Email:
                        </label>
                        <div className="form-icon relative mt-2">
                          <Icon.Mail className="w-4 h-4 absolute top-3 start-4"></Icon.Mail>
                          <input
                            name="email"
                            id="email"
                            type="email"
                            className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Email :"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="text-start">
                        <label htmlFor="comments" className="font-semibold">
                          Your Message:
                        </label>
                        <div className="form-icon relative mt-2">
                          <Icon.MessageCircle className="w-4 h-4 absolute top-3 start-4"></Icon.MessageCircle>
                          <textarea
                            name="comments"
                            id="comments"
                            className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                            placeholder="Message :"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <ReCAPTCHA
                      ref={refCaptcha}
                      sitekey={emailConfig.googleReCaptcha}
                    />

                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      onClick={sendEmail}
                      className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center mt-3"
                    >
                      Send Message
                    </button>
                    {messageSentSuccessful && (
                      <div className="relative px-4 py-2 rounded-md font-medium bg-emerald-600 border border-emerald-600 text-white block">
                        Message was sent successfully!
                      </div>
                    )}
                    {messageSendingError !== "" && (
                      <div className="relative px-4 py-2 rounded-md font-medium bg-red-600 border border-red-600 text-white block">
                        {messageSendingError}
                      </div>
                    )}
                  </form>
                </div>
                <div className="overflow-hidden absolute lg:h-[400px] h-[320px] lg:w-[400px] w-[320px] bg-indigo-600/5 bottom-0 end-0 rotate-45 -z-1 rounded-3xl"></div>
              </div>
            </div>

            <div className="lg:col-span-7 md:order-1 order-2">
              <h4 className="mb-4 text-2xl leading-normal font-medium">
                Ready to take the next step?
              </h4>
              <p className="text-slate-400">
                Contact us today to discuss your AWS cloud needs and discover
                how our team of certified experts can help you achieve your
                business goals. We're here to answer any questions you have and
                provide personalized solutions tailored to your unique
                requirements. Let's embark on your cloud journey together!
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <CookieModal />
    </>
  );
}
