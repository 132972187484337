import React, { useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../component/Navbar/navbarUtility";
import Footer from "../../component/Footer/footer";

import { MdKeyboardArrowRight, FaArrowRight } from "../../assets/icons/icons";

export default function PageCookies() {
  return (
    <>
      <Navbar />
      <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="text-3xl leading-normal font-semibold">
              Cookie Policy
            </h3>
          </div>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="md:w-3/4">
              <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                <p className="text-slate-400">
                  This Cookie Policy explains how Spoecker Cloud Consulting
                  ("we", "us", or "our") uses cookies and similar technologies
                  on our website.
                </p>

                <h5 className="text-xl font-semibold mb-4 mt-8">
                  What are cookies?
                </h5>
                <p className="text-slate-400">
                  Cookies are small text files that are stored on your device
                  (computer, smartphone, tablet) when you visit a website. They
                  are widely used to make websites work more efficiently, as
                  well as to provide information to the website owners.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  How do we use cookies?
                </h5>

                <p className="text-slate-400">
                  We use cookies for the following purposes:
                </p>
                <ul className="list-none space-x-1 text-slate-400 mt-3">
                  <li className="flex items-center mt-2">
                    Essential Cookies: These cookies are necessary for the
                    website to function properly. They enable core functionality
                    such as page navigation and access to secure areas of the
                    website. You cannot opt-out of these cookies.
                  </li>
                  <li className="flex items-center mt-2">
                    Analytics Cookies: We use analytics cookies to collect
                    information about how visitors use our website, such as the
                    pages they visit most frequently and any error messages they
                    encounter. This information helps us improve the website and
                    provide a better user experience.
                  </li>
                  <li className="flex items-center mt-2">
                    Marketing Cookies: We may use marketing cookies to track
                    visitors across websites and display relevant advertisements
                    based on their interests and browsing behavior. These
                    cookies may be set by third-party advertising networks.
                  </li>
                </ul>

                <h5 className="text-xl font-semibold mb-4 mt-8">
                  How to control cookies
                </h5>

                <p className="text-slate-400 mt-3">
                  You can control and/or delete cookies as you wish. Most web
                  browsers allow you to manage your cookie preferences. You can
                  set your browser to refuse cookies or to delete certain
                  cookies. Please note that if you choose to block cookies, you
                  may not be able to access certain features of our website.
                </p>

                <h5 className="text-xl font-semibold mb-4 mt-8">
                  Changes to this Cookie Policy
                </h5>

                <p className="text-slate-400">
                  We may update this Cookie Policy from time to time to reflect
                  changes in our practices or for other operational, legal, or
                  regulatory reasons. We encourage you to review this Cookie
                  Policy periodically for any updates or changes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
