import React from "react";
import dataEngineerAssociate from "../assets/images/aws/aws-certified-data-engineer-associate.png";
import saAssociate from "../assets/images/aws/aws-certified-solutions-architect-associate.png";
import devAssociate from "../assets/images/aws/aws-certified-developer-associate.png";
import sysOpsAssociate from "../assets/images/aws/aws-certified-sysops-administrator-associate.png";

import solutionArchitectProfessional from "../assets/images/aws/aws-certified-solutions-architect-professional.png";
import devOpsEngineerProfessional from "../assets/images/aws/aws-certified-devops-engineer-professional.png";

import machineLearningSpecialist from "../assets/images/aws/aws-certified-machine-learning-specialty.png";
import securitySpecialist from "../assets/images/aws/aws-certified-security-specialty.png";
import advancedNetworking from "../assets/images/aws/aws-certified-advanced-networking-specialty.png";

import dataAnalytics from "../assets/images/aws/aws-certified-data-analytics-specialty.png";
import database from "../assets/images/aws/aws-certified-database-specialty.png";

import aai from "../assets/images/aws/champion-authorized-instructor.png";
import wellArch from "../assets/images/aws/well-architected-proficient.png";
export default function CompanyLogo() {
  return (
    <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
      <div className="mx-auto py-4">
        <img src={solutionArchitectProfessional} className="h-2" alt="" />
      </div>

      <div className="mx-auto py-4">
        <img src={devOpsEngineerProfessional} className="h-2" alt="" />
      </div>

      <div className="mx-auto py-4">
        <img src={machineLearningSpecialist} className="h-2" alt="" />
      </div>

      <div className="mx-auto py-4">
        <img src={securitySpecialist} className="h-2" alt="" />
      </div>

      <div className="mx-auto py-4">
        <img src={advancedNetworking} className="h-2" alt="" />
      </div>
      <div className="mx-auto py-4">
        <img src={dataAnalytics} className="h-2" alt="" />
      </div>

      <div className="mx-auto py-4">
        <img src={saAssociate} className="h-2" alt="" />
      </div>
      <div className="mx-auto py-4">
        <img src={devAssociate} className="h-2" alt="" />
      </div>
      <div className="mx-auto py-4">
        <img src={sysOpsAssociate} className="h-2" alt="" />
      </div>
      <div className="mx-auto py-4">
        <img src={dataEngineerAssociate} className="h-2" alt="" />
      </div>
      <div className="mx-auto py-4">
        <img src={aai} className="h-2" alt="" />
      </div>
      <div className="mx-auto py-4">
        <img src={wellArch} className="h-2" alt="" />
      </div>
    </div>
  );
}
