import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../component/Navbar/navbarUtility";
import Footer from "../../component/Footer/footer";

import { MdKeyboardArrowRight, FaArrowRight } from "../../assets/icons/icons";

export default function PagePrivacy() {
  return (
    <>
      <Navbar />

      <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="text-3xl leading-normal font-semibold">
              Privacy Policy
            </h3>
          </div>
        </div>
      </section>

      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="md:w-3/4">
              <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                <p className="text-slate-400">
                  This Privacy Policy explains how Spoecker Cloud Consulting
                  ("we", "us", or "our") collects, uses, and protects the
                  personal information of visitors to our website.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  Information We Collect
                </h5>
                <p className="text-slate-400">
                  We may collect the following types of personal information
                  from visitors to our website: * Contact Information: Such as
                  name, email address, phone number, and mailing address, which
                  may be provided voluntarily when filling out a contact form or
                  subscribing to our newsletter. * Usage Information: Such as IP
                  address, browser type, operating system, and device
                  information, which may be automatically collected when you
                  visit our website using cookies and similar technologies. *
                  Other Information: Any other information that you voluntarily
                  provide to us when interacting with our website or
                  communicating with us.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  How We Use Your Information
                </h5>
                <p className="text-slate-400">
                  We may use the personal information we collect for the
                  following purposes: * To respond to inquiries and provide
                  customer support. * To send periodic emails and newsletters. *
                  To analyze website usage and improve our website's content and
                  user experience. * To comply with legal and regulatory
                  requirements.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  Data Security
                </h5>
                <p className="text-slate-400">
                  We take appropriate measures to safeguard the personal
                  information we collect and protect it from unauthorized
                  access, disclosure, alteration, or destruction. However,
                  please note that no method of transmission over the internet
                  or electronic storage is 100% secure, and we cannot guarantee
                  absolute security.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  Third-Party Disclosure
                </h5>
                <p className="text-slate-400">
                  We do not sell, trade, or otherwise transfer your personal
                  information to third parties without your consent, except as
                  required by law or as necessary to provide services to you. We
                  may share your information with trusted third-party service
                  providers who assist us in operating our website, conducting
                  our business, or servicing you.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">Your Rights</h5>
                <p className="text-slate-400">
                  You have the right to access, correct, update, or delete your
                  personal information at any time. You may also opt-out of
                  receiving marketing communications from us by following the
                  unsubscribe instructions included in each email.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  Changes to this Privacy Policy
                </h5>
                <p className="text-slate-400">
                  We reserve the right to update or modify this Privacy Policy
                  at any time. Any changes will be effective immediately upon
                  posting on this page. We encourage you to review this Privacy
                  Policy periodically for any updates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
